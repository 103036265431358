import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const Directories = (props) => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
      <div
        className="client-area client-area-pd bg-overlay pd-top-118"
        style={{ backgroundImage: `url(${rpdata?.gallery?.[2]})` }}
      >
        <div className="container">
          <div className="row justify-content-center text-lg-left text-center">
            <div className="col-lg-9">
              <div className="section-title style-white mb-0">
                <h6>{rpdata?.dbSlogan?.[3].slogan}</h6>
                <h2>{rpdata?.dbSlogan?.[4].slogan}</h2>
              </div>
            </div>
            <div className="col-lg-3 align-self-end text-lg-right mt-4 mt-lg-0 go-top">
              <Link className="btn btn-base" to="/contact">
                FREE ESTIMATE
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
      <h2 className="pd-top-100 text-center">Find Us</h2>
      <div className="row justify-content-center pd-top-20">
          <div className="col-lg-3 col-md-6">
            <a href="https://www.yelp.com/biz/home-improvements-by-carlos-bauxite">
            <img src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/A-Directorios%2Fyelp.png?alt=media&token=5c05b791-5506-41d8-b416-01eb9ad29d9e"></img>
            </a>
          </div>
          <div className="col-lg-3 col-md-6">
          <a href="https://www.homeadvisor.com/rated.HomeImprovementsby.67234398.html"><img src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/A-Directorios%2Fhome-advisor-01.png?alt=media&token=d0d1086e-6eba-440e-ac02-9ee0e70e053b"></img>
          </a>
          </div>
          <div className="col-lg-3 col-md-6">
          <a href="https://www.facebook.com/homeImprovementremodelingbycarlos/"><img src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/A-Directorios%2Ffacebook-logo-1-1%20-%20copia.png?alt=media&token=b60e04ec-022e-43c8-a139-bbd60b783977"></img>
          </a> 
          </div>
          <div className="col-lg-3 col-md-6">
          <a href="https://twitter.com/ImprovementBy"><img src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/A-Directorios%2Ftwitter_PNG12.png?alt=media&token=4dc0edf6-f818-4f82-b0c2-71d5f91552a9"></img>
          </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Directories;
