import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

//

const LiveChat = (props) => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
<div class="elfsight-app-c6207c78-4b34-46ff-bf09-d38ce1516f62"></div>
      <div className="visor_Counter">
<div class="elfsight-app-60ffc215-2977-4aaa-bb17-7ba9bd9d047f"></div>
      </div>
    </div>
  );
};

export default LiveChat;
